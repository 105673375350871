import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, database } from "../../firebase";
import { getDatabase, ref, set, get ,query , orderByChild , equalTo} from "firebase/database";
import { zet_API_Url, zet_API_Key, zet_Response_Url } from "../../utils/Zetapi.js";
import styles from "./Signup.module.css";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

function Signup() {
  const navigate = useNavigate();
  const [values, setValues] = useState({ // Fix the declaration here
    name: "",
    email: "",
  });

 

  const [agentId, setAgentId] = useState("");
  const [hash, setHash] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const handleSubmission = () => {
    setSubmitButtonDisabled(true);
    handleGoogleSignin();
  };
 
  const handleGoogleSignin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        setValues((prev) => ({
          ...prev,
          name: user.displayName || "",
          email: user.email || "",
        }));
        setErrorMsg("");
        navigate("/");
  
        try {
          const db = getDatabase();
          const userRef = ref(db, 'users');
          const emailQuery = query(userRef, orderByChild('email'), equalTo(user.email));
  
          const snapshot = await get(emailQuery);
  
          if (snapshot.exists()) {
             
            console.log("User data found  in database so the agent id will be retriveted by that .");

            // User data exists in the database
            const userData = snapshot.val();
            const userId = Object.keys(userData)[0];
            // Get the first user ID matching the email (assuming unique emails)
            const { agent_id, hash } = userData[userId];
           
  
            // Update the state with the retrieved values from the database
            setAgentId(agent_id);
            setHash(hash);
  
            window.location.href = zet_Response_Url + hash;
          } else {
            // User data does not exist in the database, proceed with API call
            console.log("User data not found in the database.");
  
            // ... Write user data to the database and proceed with API call ...

            
  
            
  
            const requestBody = {
              name: user.displayName,
              id: user.uid,
            };

             fetch("https://referapi.bulkpe.in/api/postRequest", 
            //  fetch("http://localhost:/api/postRequest", 
             {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "api_key": zet_API_Key,
              },
              body: JSON.stringify(requestBody),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`API request failed with status ${response.status}`);
                }
                
                return response.json();

              })
              .then((response) => {
                
                const agent_id = response.agentId;
                const hash = response.hash;
               

                setAgentId(agent_id);
                setHash(hash);

                updateUserData(user.uid, user.displayName, user.email, agent_id, hash);
                

                window.location.href = zet_Response_Url + hash;
              })
              .catch((error) => {
                console.error("API request error:", error);
              });

          }
        } catch (error) {
          console.error("Error reading user data from database:", error);
          setErrorMsg("Error occurred while processing the request.");
        }
      })
      .catch((error) => {
        console.error("Error occurred during Google sign-in:", error);
        setErrorMsg("Google sign-in error or popup closed.");
        setSubmitButtonDisabled(false);
      });
  };

  function writeUserData(userId, name, email) {
    const db = getDatabase();
    set(ref(db, 'users/' + userId), {
      name: name,
      email: email,
      agent_id : "",
      hash : "",
    });
  }
    
  function updateUserData(userId, name, email, agent_id, hash) {
    const db = getDatabase();
    set(ref(db, `users/${userId}`), {
      name: name,
      email: email,
      agent_id: agent_id,
      hash: hash,
    });
  }

  

  function readUserData(userId) {
    const userRef = ref(database, 'users/' + userId);
    get(userRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setValues((prev) => ({
            ...prev,
            email: userData.email || "",
          }));
        }
      })
      .catch((error) => {
        console.error("Error reading user data:", error);
      });
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerBox}>
      {/* <img
          src="https://bulkpe.s3.ap-south-1.amazonaws.com/website/logo+with+name.svg"
          alt="Bulkpe Logo"
          className={styles.headingImage}
          style={{ height: "80px", width: "180px" }} // Adjust the height and width as per your requirement
        /> */}
         <div>
      <h4 style={{ marginBottom: '5px',marginLeft : '15px' }}>Refer & Earn program</h4>
      {/* <h5 style={{ marginTop: '0',marginLeft : '15px'}}>
        Contact <a href="mailto:support@bulkpe.in">support@bulkpe.in</a> for earned payouts.
      </h5> */}
      <br/>
      
    </div>

         
        <div className={styles.footer}>
          <b className={styles.error}>{errorMsg}</b>
          <button onClick={handleSubmission} disabled={submitButtonDisabled}>
            Login & Sign Up with Google
          </button>
        </div>
      </div>
    </div>
  );

}

export default Signup;