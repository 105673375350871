import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import { getDatabase } from "firebase/database";





// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBglkpmywEpMcbsiJ0rVplYtsYhybfHrs0",
  authDomain: "refer-app-bulkpe.firebaseapp.com",
  projectId: "refer-app-bulkpe",
  storageBucket: "refer-app-bulkpe.appspot.com",
  messagingSenderId: "916682933410",
  appId: "1:916682933410:web:3034f0001d534b6f0403fb",
  measurementId: "G-JFJL8HNWM8"
};

// Initialize Firebase
// Import the functions you need from the SDKs you need


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const database = getDatabase(app);
export { auth, provider, database };










